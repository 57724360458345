<template>
    <BlockUI :blocked="loadingCard">
        <div class="chart-wrapper">
            <div class="chart-container">
                <Chart type="doughnut" :data="dataChart" :options="options" />
                <div class="donut-inner text-center">
                    <!-- <h5>Checkin</h5> -->
                    <span>{{percentageText}}</span>
                </div>
            </div>
        </div>
        <div class="text-center mt-3">
            <div class="text-500 font-medium text-3xl">
                <span class="text-primary">{{checkedIn}}</span> / {{totalUserActive}}
            </div>
            <div class="text-500 font-medium text-lg">
                Employees have checked in
            </div>
            <div class="text-500 font-medium text-2xl mt-3">
                {{employeeRegistered}}
            </div>
            <div class="text-500 font-medium text-base">
                Employee Total
            </div>
        </div>
    </BlockUI>
</template>

<script>

export default {
    data() {
        return {
            loadingCard: false,

            percentageText: '0%', // Initial percentage text
            checkedIn: 0,
            totalUserActive:0,
            employeeRegistered: 0,

            dataChart: {
                labels: ['Checked In', 'Total Employees'],
                datasets: [
                    {
                        data: [0, 0], // Placeholder values
                        backgroundColor: ['#00E396', '#F2F2F2'],
                        hoverBackgroundColor: ['#00E396', '#F2F2F2']
                    }
                ]
            },
            options: {
                responsive: true,
                aspectRatio: 1,
                cutout: '70%', // For making it a donut chart
                plugins: {
                    legend: {
                        display: false // Hide the legend
                    },
                    tooltip: {
                        enabled: true // Enable tooltips
                    },
                }
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loadingCard = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/checkin-chart',
                params: {
                    // Add your API request parameters here
                }
            })
            .then(res => {
                this.checkedIn = res?.data?.data?.datasets[0].total_user_checkin;
                this.totalUserActive = res?.data?.data?.datasets[0].total_user_active;
                this.employeeRegistered = res?.data?.data?.datasets[0].total_user_registered;

                // Update the chart data
                this.dataChart.datasets[0].data = [this.checkedIn, this.totalUserActive - this.checkedIn];

                // Calculate the new percentage
                const percentage = this.totalUserActive > 0 ? (this.checkedIn / this.totalUserActive) * 100 : 0;
                // const percentage = res?.data?.data?.datasets[0].persen ;

                // Update the central text with the calculated percentage
                this.percentageText = `${percentage.toFixed(0)}%`;

                this.loadingCard = false;
            })
            .catch((err) => {
                this.loadingCard = false;
                console.log(err);
            });
        }
    }
}
</script>

<style>
.chart-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
}

.chart-container {
  width: 200px;
  height: 200px;
}

.donut-inner {
   margin-top: -112px;
   margin-bottom: 100px;
   justify-content: center; /* Center horizontally */
}

/* .donut-inner h5 {
   margin-bottom: 5px;
   margin-top: 0;
} */

.donut-inner span {
   font-size: 25px;
}
</style>
