<template>
    <BlockUI :blocked="loadingCard">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <Dropdown v-model="filters.tipe" :options="dataDropdownTipe" optionLabel="name" optionValue="code" placeholder="Pilih Tipe" />    
            </div>
        </div>
        <Chart type="line" :data="dataChart" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		// "filter",
		// "filter2",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingCard: false,

            // dataDropdown
            dataDropdownTipe: [
                {name: 'Value', code: 'value'},
                {name: 'Percentage', code: 'percentage'},
            ],

            // filter
            filters: {
                tipe: 'value',
            },

            dataChart: null,
            options: {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                }
            },
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.getData()
                }, 500);
            },
            deep: true
        },
        // filter: {
        //     handler() {
        //         setTimeout(() => {
        //             this.getData();
        //         }, 500);
        //     },
        //     deep: true
        // },
        // filter2: {
        //     handler() {
        //         setTimeout(() => {
        //             this.getData();
        //         }, 500);
        //     },
        //     deep: true
        // },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        getData(){

            this.loadingCard= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/attendance-position-chart',
                params: {
                    // "userweb_id": this.filter.userweb_id,
                    // "periode": this.filter2.period,
                    "type": this.filters.tipe,
                }
            })
            .then(res => {
                this.dataChart = res.data.data;

                this.loadingCard= false;
                
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
	},
}
</script>