<template>
  <BlockUI :blocked="loading">
  <div class="table-wrapper">
    <table class="p-table p-component p-mt-2" style="border-collapse:unset;border:1px solid #ccd6e2 !important;margin-bottom:unset !important">
      <thead class="sticky-header">
        <tr>
          <th rowspan="2">Position</th>
          <th colspan="3">Total Employee</th>
          <th rowspan="2">Masuk</th>
          <th rowspan="2">Sakit</th>
          <th rowspan="2">Izin</th>
          <th rowspan="2">Cuti</th>
          <th rowspan="2">Alpha</th>
          <th colspan="2">Percentage</th>
        </tr>
        <tr>
          <th>Registered</th>
          <th>Active</th>
          <th>Off</th>
          <th>Registered</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in attendanceData" :key="index">
          <td>{{ row?.user_position }}</td>
          <td>{{ row?.total_user_registered }}</td>
          <td>{{ row?.total_user_active }}</td>
          <td>{{ row?.total_user_off }}</td>
          <td>{{ row?.total_user_masuk }}</td>
          <td>{{ row?.total_user_sakit }}</td>
          <td>{{ row?.total_user_izin }}</td>
          <td>{{ row?.total_user_cuti }}</td>
          <td>{{ row?.total_user_alpha }}</td>
          <td>{{ row?.percentage_user_registered }}</td>
          <td>{{ row?.percentage_user_active }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="background:#e0ebf9 !important;font-size:14px !important;font-weight:600 !important;position:sticky;bottom:0;">
          <td>Total</td>
          <td>{{ total?.total_user_registered }}</td>
          <td>{{ total?.total_user_active }}</td>
          <td>{{ total?.total_user_off }}</td>
          <td>{{ total?.total_user_masuk }}</td>
          <td>{{ total?.total_user_sakit }}</td>
          <td>{{ total?.total_user_izin }}</td>
          <td>{{ total?.total_user_cuti }}</td>
          <td>{{ total?.total_user_alpha }}</td>
          <td>{{ total?.percentage_user_registered }}</td>
          <td>{{ total?.percentage_user_active }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
  </BlockUI>
</template>

<script>
export default {
  data() {
    return {
      //loading
      loading: false,

      data: null,

      attendanceData: null,
      total: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // DATA
    getData(){

        this.loading=true;

        this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/dashboard/attendance-summary-position',
            params: {
              // 
            }
        })
        .then(res => {
          this.data = res.data.data;
          this.attendanceData = this.data?.detail;
          this.total = this.data?.total;

          this.loading=false;
        })
        .catch((err) => {
            console.log(err);
            this.loading=false;
        });
      
    },
  }
};
</script>

<style scoped>
.p-table {
  width: 100%;
  border-collapse: collapse;
}

.p-table th,
.p-table td {
  border: 1px solid #eaeaea;
  padding: 8px;
  text-align: center;
}

.p-table th {
  background-color: #f9f9f9;
}

.p-table tfoot tr {
  font-weight: bold;
}

.table-wrapper {
  max-height: 300px; /* Set max height for table scroll */
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10; /* Set a higher z-index to ensure visibility */
}

.sticky-header th {
  background-color: #e0ebf9;
}
</style>
