<template>
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
        <div>
            <Button type="button" :label="'Total '+this.total_marker" icon="pi pi-map" class="p-button-primary"/>
        </div>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
        </span>
    </div>
        <BlockUI :blocked="loading">
        <div class="map" id="map-employee-checkin"></div>
    </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    props: [
		// "filter",
		// "filter2",
    ],
    data() {
        return {
            // maps
            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_marker: 0,

            outletTypeColors: {},  // Menyimpan warna berdasarkan tipe outlet
            legendControl: null,  // Simpan referensi ke kontrol legenda
            
        }
    },
    mounted() {

        // eslint-disable-next-line no-undef
        this.map = L.map('map-employee-checkin', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

    },
    created(){
        this.showMarker();
    },
    watch: {
        // filter: {
        //     handler() {
        //         setTimeout(() => {
        //             this.showMarker() // Panggil metode yang ingin dijalankan
        //         }, 500);
        //     },
        //     deep: true
        // },
        // filter2: {
        //     handler() {
        //         setTimeout(() => {
        //             this.showMarker() // Panggil metode yang ingin dijalankan
        //         }, 500);
        //     },
        //     deep: true
        // },
    },
    methods:{
        //MAPS
        showMarker(){

            this.loading= true;

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/employee-checkin',
                params: {
                    // "userweb_id": this.filter.userweb_id,
                    // "periode": this.filter2.period,
                }
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_marker = res.data.data.length;

                // Reset outletTypeColors untuk memulai dari awal
                this.outletTypeColors = {};

                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.cust_in_lat != null && value.cust_in_lng != null){

                        var htmlcontent = "<b>Project</b> : " + value.project_name + "</b><br/>";
                            htmlcontent += "<b>User</b> : " + value.username +' - '+ value.nama + "<br/>";
                            htmlcontent += "<b>Area</b> : " + value.provinsi_name + ' - ' + value.kabupaten_name + ' - ' + value.kecamatan_name +"<br/>";
                            htmlcontent += "<b>Account Type</b> : " + value.cust_account_type + "<br/>";
                            htmlcontent += "<b>Venue</b> : " + value.cust_location_name + "<br/>";
                            htmlcontent += "<b>Checkin Time</b> : " + value.cust_in_time + "<br/>";

                    // Dapatkan warna berdasarkan tipe outlet
                    const markerColor = value.hexcolor;
                    
                     // Simpan warna berdasarkan tipe outlet
                    if (!this.outletTypeColors[value.project_name]) {
                        this.outletTypeColors[value.project_name] = markerColor;
                    }

                    // Gunakan marker dengan warna
                    // eslint-disable-next-line no-undef
                    var marker = L.circleMarker([value.cust_in_lat, value.cust_in_lng], {
                        color: markerColor, // Set warna marker sesuai tipe outlet
                        radius: 8, // Ukuran lingkaran
                        fillColor: markerColor,
                        fillOpacity: 0.8
                    });

                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                },this); 
                
                this.map.addLayer(markers);

                this.showLegend();

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        removeExistingLegend() {
            if (this.legendControl) {
                this.map.removeControl(this.legendControl);
                this.legendControl = null;
            }
        },
        showLegend() {
            setTimeout(() => {
                this.removeExistingLegend();  // Hapus legenda yang ada

                // eslint-disable-next-line no-undef
                this.legendControl = L.control({ position: "bottomleft" });

                this.legendControl.onAdd = () => {

                    // eslint-disable-next-line no-undef
                    var div = L.DomUtil.create("div", "legend");
                    div.innerHTML += "<h4>Keterangan</h4>";

                    if (Object.keys(this.outletTypeColors).length === 0) {
                        div.innerHTML += "<p>Tidak ada project yang tersedia.</p>";
                        return div;
                    }

                    Object.keys(this.outletTypeColors).forEach(type => {
                        const color = this.outletTypeColors[type];
                        div.innerHTML += `<i style="background: ${color}; width: 18px; height: 18px; display: inline-block; margin-right: 8px;"></i><span>${type}</span><br>`;
                    });

                    return div;
                };

                this.legendControl.addTo(this.map);  // Tambah legenda baru ke peta
            }, 250);
        },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
